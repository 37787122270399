<template>
  <div class="pubeixiVoteGroupList_container global_vote_font">
    <div class="voteGroupList_search">
      <el-input
        ref="searchRef"
        v-model="localSearch.content"
        @blur="onblur"
        @focus="onfocus"
        @input="onInput"
      ></el-input>
      <span
        @click="setFocus"
        v-if="!localSearch.content && showIcon"
        class="voteGroupList_search_placeholder"
        ><i class="el-icon-search"></i>
        <span> 请输入专家姓名搜索</span>
      </span>
    </div>
    <pubeixiVoteType
      ref="voteTypeRef"
      :period="search.period"
      :periodList="config.periodList"
      :activeType="localSearch.activeType"
      @selectChange="selectChange"
      @handleVoteMethodGroup="handleVoteMethodGroup"
      @handleVoteMethodName="handleVoteMethodName"
      :voteStartTime="voteStartTime"
      :voteEndTime="voteEndTime"
      :timeStyle="getTimeStyle()"
      :getSelectClass="getSelectClass()"
      :getButtonClass="getButtonClass()"
    ></pubeixiVoteType>
    <div class="voteGroupList_content">
      <div class="group_name pubeixi_group_name_color" v-if="loadMode()">
        {{ groupNum }}
      </div>
      <div class="scroll_block">
        <div ref="wrapper" class="voteGroupList_content_scroll">
          <div class="scroll_div" ref="voteItem">
            <div
              v-for="(item, index) in getGroupsDataList()"
              :key="item.vote_id"
              @click="goToDetail(item)"
            >
              <div class="voteGroupList_doctor_block">
                <div class="doctor_index">{{ index + 1 }}</div>
                <div class="pubeixi_doctor_profile">
                  <img :src="item.profile" />
                </div>
                <div class="doctor_info">
                  <p class="pubeixi_doctor_info_name">
                    {{ item.doctor_name }} 医生
                  </p>
                  <p class="doctor_info_hospital">{{ item.doctor_hospital }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pubeixi_voteFooter">
      <p>
        本项目中会传递医药前沿信息、研究进展和科学信息交流,仅供医疗卫生专业人士访问、参考及学习,请确保您的身份是医疗卫生专业人士,否则请退出。视频未经讲者允许,禁止任何形式转载
      </p>
    </div>
  </div>
</template>
<script>
import tool from "@/common/tool";
import urls from "@/common/urls";
import config from "@/common/config";
import wx from "@/common/weixin";
import BScroll from "better-scroll";
import pubeixiVoteType from "./pubeixiVoteType.vue";
import { ElInput } from "element-plus";
export default {
  name: "pubeixiVoteGroupList",
  components: {
    pubeixiVoteType,
    ElInput,
  },
  data() {
    return {
      groupData: {},
      essence_video: "", //精华视频
      config: {
        myVoteList: [],
        periodList: [],
        groupData: [],
      },
      localSearch: {
        content: "",
        group: "",
      },
      search: {
        project_id: config.projectId,
        company_id: config.companyId,
        class_group: "",
        period: "",
        page: 1,
        page_size: 1000,
      },
      showIcon: true,
      scroll: null,
      groupNum: "",
      voteStartTime: [],
      voteEndTime: [],
      isVote: "",
      role: null,
      class_group: null,
    };
  },
  created() {
    // let content = tool.getStorage("localSearch", sessionStorage);
    // if (content) this.localSearch.content = content;
    this.getStorageData();
    this.search = {
      ...this.search,
      ...this.$route.query,
      period: Number(this.$route.query.period) || this.$route.query.period,
    };
    console.log("  this.search :>> ", this.search);
    this.role = Number(this.$route.query.role);
    this.loadMode();
    this.getTitle();
  },
  mounted() {
    this.config.periodList = this.formatPeriodList([1, 2, 3, 4, 5, 6, 7]);
    this.loadPeriodList();
  },
  methods: {
    getTitle() {
      document.getElementsByTagName("title")[0].innerHTML = "文献精读视频专区";
    },

    //下拉框
    getSelectClass() {
      if (!this.loadMode()) {
        return "pubeixi_mass_type";
      } else if (!this.role) return "pubeixi_mass_type";
      else return "pubeixi_profic_type";
    },
    //类型按钮
    getButtonClass() {
      if (!this.loadMode()) {
        return "pubeixi_mass_type_item_active";
      } else if (!this.role) return "pubeixi_mass_type_item_active";
      else return "pubeixi_profic_type_item_active";
    },
    //投票时间样式
    getTimeStyle() {
      if (!this.loadMode()) {
        return "background";
      } else if (!this.role) return "background";
      else return "background_new";
    },
    //判断模式
    loadMode() {
      //true 新模式 false 旧模式
      return String(this.search.period).includes("-");
    },
    initGroupData() {
      let url = tool.getURL(urls.vote.groupVote, this.search);

      this.$axios
        .get(url)
        .then((res) => {
          let data = res.data;
          this.groupNum = `第${tool.intToChinese(this.search.class_group)}组:${
            data[0].courseware_name
          }`;
          let item =
            data.length > 0
              ? { ...data[0] }
              : {
                  start_time: tool.getDate(null, "yyyy-MM-dd"),
                  end_time: tool.getDate(null, "yyyy-MM-dd"),
                };
          this.essence_video = res.data[0].essence_video;
          this.class_group = res.data[0].class_group;
          this.voteStartTime = item.start_time.split(/[\s:-]/);
          this.voteEndTime = item.end_time.split(/[\s:-]/);
          this.isVote = this.isCanVote(item);
          this.loadMyVote(res.data);
        })
        .catch((err) => {});
    },
    loadMyVote(data) {
      let url = tool.getURL(urls.vote.myVote, this.search);

      this.$axios
        .get(url)
        .then((res) => {
          this.config.myVoteList = res.data.filter(
            (item) => tool.getTime(item.subdate) >= tool.getTime(tool.getDate())
          );
          this.initData(data);
        })
        .catch((err) => {});
    },
    getStorageData() {
      let searchData = tool.getStorage("searchData", sessionStorage);
      if (searchData) {
        this.search = searchData.search;
        this.localSearch = searchData.localSearch;
      }
    },
    setStorageData() {
      tool.setStorage(
        "searchData",
        {
          search: this.search,
          localSearch: this.localSearch,
        },
        sessionStorage
      );
    },
    initData(data) {
      let voteConfig = {};
      this.config.myVoteList.forEach((item) => {
        voteConfig[item.vote_id] = voteConfig[item.vote_id] || 0;
        voteConfig[item.vote_id] = voteConfig[item.vote_id] += 1;
      });
      let totalCount = data.reduce((total, item) => total + item.vote_count, 0);
      let tempGroup = [];
      let rank = 0;
      let voteCount = 0;
      this.config.groupData = data
        .sort((prep, next) => next.vote_count - prep.vote_count)
        .map((item, index) => {
          if (!tempGroup.includes(item.courseware_name))
            tempGroup.push(item.courseware_name);
          let currentVoteCount = item.vote_count;
          if (voteCount !== currentVoteCount) {
            rank = index + 1;
            voteCount = currentVoteCount;
          }
          rank = rank || 1;
          item.rank = rank;
          item.voteForMeCount = voteConfig[item.id] || 0;
          item.totalCount = totalCount;
          item.class_group =
            item.class_group || tempGroup.indexOf(item.courseware_name) + 1;
          return item;
        });
      this.config.groupData = this.config.groupData
        .sort((prep, next) =>
          prep.doctor_china_name > next.doctor_china_name ? 1 : -1
        )
        .sort((prep, next) => next.vote_count - prep.vote_count);
      this.$nextTick(() => {
        this.initParams();
        this.initScroll();
      });
    },
    isCanVote(item) {
      return (
        tool.getTime(item.start_time) < Date.now() &&
        tool.getTime(item.end_time) > Date.now()
      );
    },
    getGroupsDataList() {
      let result = this.config.groupData.filter((item) => {
        return item.doctor_name.includes(this.localSearch.content);
      });
      console.log(result);
      return result;
    },

    formatPeriodList(arr) {
      let numbs = [];
      arr.forEach((item) => {
        if (!item.length || item.length <= 1) {
          item = `2022-${item}`;
        }
        numbs.push(item);
      });
      return numbs.map((num) => {
        let year = num.split("-")[0];
        let month = parseInt(num.split("-")[1]);
        return {
          value: year === "2022" ? month : `${year}-${month}`,
          label: `${year}第${tool.intToChinese(month)}期`,
        };
      });
    },
    getCheckType() {
      return this.$route.query.check_type == 2 ? 1 : 0;
    },
    loadPeriodList() {
      let url = tool.getURL(urls.vote.periodList, {
        project_id: config.projectId,
        company_id: config.companyId,
        check_type: this.getCheckType(),
      });

      this.$axios
        .get(url)
        .then((res) => {
          this.config.periodList = this.formatPeriodList(res.data);
          this.initGroupData();
        })
        .catch((err) => {});
    },
    initParams() {
      let item = this.config.groupData[0];
      let logo = item.class_group_image;
      let title = `${item.sign_project} ${item.courseware_name}`;
      let search = { period: this.search.period };
      let params = {
        title,
        desc: `我们是${item.sign_project}医生。\n快来为我们点赞加油吧!`,
        url: "",
        // url: tool.getURL(`${config.host}/#/votingGroupList`, search),
        logo,
      };
      this.$nextTick(() => {
        wx.sharePageMessage(params);
      });
    },

    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.wrapper, {
            click: true,
            probeType: 3,
            mouseWheel: true,
            scrollbar: false, //隐藏滚动条
            // scrollbar: {
            //   fade: false,
            //   interactive: false, // 1.8.0 新增
            // },
          });
          this.scroll.on("scroll", (pos) => {});
        } else {
          this.scroll.refresh();
          this.scroll.scrollTo(0, 0);
        }
      });
    },
    back() {
      // let path = !this.getCheckType() ? "pubeixiVote" : "voteTest";
      let path = "pubeixiVote";
      this.$router.push({
        path,
        query: { role: this.role, period: this.search.period },
      });
    },
    goToDetail(item) {
      this.$router.push({
        path: "/pubeixiVoteDetail",
        query: {
          vote_id: item.id,
          role: this.role,
          mode: this.loadMode() ? "1" : "0",
        },
      });
    },
    //期数
    selectChange(period) {
      if (period) {
        this.search.period = period;
      }
      this.loadMode();
      this.setStorageData();
      this.back();
      this.getTitle();
    },
    onInput() {
      this.setStorageData();
      this.$nextTick(() => {
        this.initScroll();
      });
    },
    setFocus() {
      this.showIcon = false;
      this.$refs.searchRef.focus();
    },
    onblur() {
      this.$refs.searchRef.blur();
      this.showIcon = true;
    },
    onfocus() {
      this.showIcon = false;
    },
    //分组投票
    handleVoteMethodGroup() {
      this.localSearch.activeType = 0;
      this.setStorageData();
      this.back();
    },
    //名称
    handleVoteMethodName() {
      this.localSearch.activeType = 1;
      this.setStorageData();
      this.back();
    },
    player() {
      this.$refs.videoRef.player();
    },
    playEnd() {
      this.$refs.videoRef.playEnd();
    },
  },
};
</script>
<style >
.pubeixiVoteGroupList_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f2f2f3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.pubeixiVoteGroupList_container .voteGroupList_search {
  padding: 0 5.33vw;
  width: 100%;
  height: 6.67vw;
  position: relative;
  color: #dcdfe6;
  margin-top: 2.67vw;
  display: flex;
  align-items: center;
  flex: none;
}
.pubeixiVoteGroupList_container .voteGroupList_search input {
  font-size: 3.2vw !important;
  padding-left: 7vw;
  height: 6.67vw;
  line-height: 6.67vw;
  border: none;
  text-align: center !important;
  border-radius: 4vw;
}
.pubeixiVoteGroupList_container .voteGroupList_search_placeholder {
  font-size: 3.2vw;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #c6c8c7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pubeixiVoteGroupList_container .voteGroupList_search_placeholder i {
  margin-right: 1.33vw;
  color: #5f5f5f;
}

.pubeixiVoteGroupList_container
  .voteGroupList_type
  .el-select-dropdown__item.hover {
  background: #fff !important;
}
.pubeixiVoteGroupList_container .voteGroupList_type .el-input__suffix {
  display: none;
}
.pubeixiVoteGroupList_container .voteGroupList_type .el-select-dropdown__item {
  text-align: center;
}
.pubeixiVoteGroupList_container .voteGroupList_time {
  margin-top: 3.67vw;
  height: 8vw;
  text-align: center;
  line-height: 8vw;
  font-size: 3.2vw;
  background: #eadce4;
  font-weight: bold;
}
.pubeixiVoteGroupList_container .voteGroupList_content {
  flex: 1 0 0;
  width: 100%;
  font-size: 5vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 3.67vw 5.33vw 3.67vw 5.33vw;
}

.pubeixiVoteGroupList_container .voteGroupList_content .scroll_block {
  overflow: hidden;
  /* flex: 1; */
  height: 100%;
}
.pubeixiVoteGroupList_container .voteGroupList_content_scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pubeixiVoteGroupList_container .scroll_block .bscroll-vertical-scrollbar {
  top: 30.5vw !important;
}
.pubeixiVoteGroupList_container .scroll_block .bscroll-indicator {
  height: 150px !important;
}
.pubeixiVoteGroupList_container .pubeixiVoteGroupList_container .prompt_info {
  vertical-align: middle;
  padding: 2.67vw 5.33vw;
  background: #ede4e9;
  font-size: 2vw;
  flex: none;
  display: flex;
  align-items: center;
}
.pubeixiVoteGroupList_container .prompt_info p {
  line-height: 3.5vw;
}

.pubeixiVoteGroupList_container .voteGroupList_type_item {
  font-size: 3.2vw;
  height: 5.33vw;
  line-height: 1vw;
  width: 29vw;
  border-radius: 5.33vw;
  background-color: #909499;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.pubeixiVoteGroupList_container .voteGroupList_type_item_active {
  font-size: 3.2vw;
  height: 5.33vw;
  line-height: 1vw;
  width: 29vw;
  border-radius: 5.33vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* background-color: #9e004c; */
}
.pubeixiVoteGroupList_container .group_name {
  flex: none;
  min-height: 6.67vw;
  word-break: break-all;
  line-height: 5vw;
  font-size: 3.5vw;
  background: #fff;
  padding: 2vw;
  box-shadow: 0 0 1.52vw 0.5vw #82a4c5;
  font-weight: bold;
  border-radius: 1.5vw;
  margin-bottom: 4vw;
}
.pubeixiVoteGroupList_container .pubeixi_group_name_color {
  color: #00438d;
}
.pubeixiVoteGroupList_container .group_name_color_new {
  color: #00438d;
}
.pubeixiVoteGroupList_container .background {
  background: #eadce4;
}
.pubeixiVoteGroupList_container .background_new {
  background: #f3f8e3;
}
.pubeixiVoteGroupList_container .icon_content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.pubeixiVoteGroupList_container .icon_content_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
}
.pubeixiVoteGroupList_container .icon_content .icon_small {
  width: 4vw;
  height: 4vw;
}
.pubeixiVoteGroupList_container .icon_content .icon_big {
  width: 5vw;
  height: 5vw;
}
.pubeixiVoteGroupList_container .pubeixi_voteFooter {
  vertical-align: middle;
  padding: 2.67vw 5.33vw;
  font-size: 2vw;
  flex: none;
  display: flex;
  align-items: center;
  background: linear-gradient(#e6e9ee, #d1dae5);
}
.pubeixi_voteFooter p {
  line-height: 3.5vw;
}
.pubeixiVoteGroupList_container .voteGroupList_doctor_block {
  width: 100%;
  height: 15vw;
  margin-bottom: 4vw;
  display: flex;
}
.pubeixiVoteGroupList_container .doctor_index {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw;
  width: 8vw;
}
.pubeixiVoteGroupList_container .pubeixi_pubeixi_doctor_profile {
  height: 100%;
  width: 15vw;
}
.pubeixiVoteGroupList_container .pubeixi_doctor_profile img {
  height: 100%;
  width: 100%;
  border: 1px solid #004890;
  border-radius: 50%;
}
.pubeixiVoteGroupList_container .doctor_info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 4vw;
  margin-left: 2vw;
}
.pubeixiVoteGroupList_container .pubeixi_doctor_info_name {
  color: #004890;
  font-weight: bold;
}
.pubeixiVoteGroupList_container .doctor_info_hospital {
}
</style>